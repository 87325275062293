<template>
  <v-card class="mx-auto" :disabled="ldg" :loading="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{ name: 'servicios.rs_refounds' }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-row dense>
        <v-col cols="12" v-if="data.discharged">
          <v-alert type="success" class="text-center mb-0" dense>
            La solicitud fue terminada el {{ data.discharged }}
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          v-if="
            data.rs_refound_rejected &&
            data.rs_refound_rejected.created_at &&
            data.rs_refound_rejected.rs_refound_rejected_type_id == 2
          "
        >
          <v-alert
            :color="data.rs_refound_rejected.response == 0 ? 'orange' : 'grey'"
            class="text-center mb-0 white--text"
            dense
          >
            {{ data.rs_refound_rejected.created_at }} |
            {{
              data.rs_refound_rejected.rs_refound_rejected_type
                .rs_refound_rejected_type
            }}
            {{ data.rs_refound_rejected.response == 0 ? "" : " | RESPONDIDO" }}
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          v-if="
            data.rs_refound_rejected &&
            data.rs_refound_rejected.created_at &&
            data.rs_refound_rejected.rs_refound_rejected_type_id == 1
          "
        >
          <v-alert color="red" class="text-center mb-0 white--text" dense>
            {{ data.rs_refound_rejected.created_at }} |
            {{
              data.rs_refound_rejected.rs_refound_rejected_type
                .rs_refound_rejected_type
            }}
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="!data.insured">
          <v-alert type="warning" dense>
            <v-row align="center">
              <v-col class="grow">
                El asegurado no se encuentra en sistema
              </v-col>
              <v-col class="shrink">
                <v-btn small @click.prevent="insuredsDlg">
                  Buscar
                  <v-icon right> mdi-magnify </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'SOLICITUD'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="
                        data.rs_comment_detail_pending
                          ? 'warning'
                          : 'grey darken-1'
                      "
                      x-small
                      text
                      dark
                      @click.prevent="rsCommentDetailDlg"
                    >
                      <v-icon small> mdi-email </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Comentarios | Observaciones'" />
                </v-tooltip>
                <v-tooltip
                  v-if="data.rs_service && data.rs_service.url_survey"
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="teal"
                      x-small
                      text
                      dark
                      @click.prevent="
                        fileDownload(
                          data.rs_service.survey_base64,
                          data.rs_service.survey_ext,
                          'encuesta_serv'
                        )
                      "
                    >
                      <v-icon small> mdi-file-eye </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Ver encuesta'" />
                </v-tooltip>
                <v-tooltip v-if="log.permissions.rss_rs_refounds.update" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="data.rs_administratives > 0 ? 'indigo' : 'grey'"
                      x-small
                      text
                      dark
                      class="ml-1"
                      :to="{
                        name: 'servicios.rs_refounds.administratives',
                        params: { id: data.id },
                      }"
                    >
                      <v-icon small> mdi-note-multiple </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Seg. Administrativo'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    log.permissions.rss_rs_refounds.update &&
                    log.role_id != 7 &&
                    log.role_id != 8 &&
                    data.insured_id &&
                    data.rs_refound_bills_xmls == 0 &&
                    data.refound_with_bills &&
                    (!data.rs_service ||
                      data.rs_service.letter_generated_cancelled) &&
                    (!data.rs_refound_rejected ||
                      data.rs_refound_rejected.response)
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="warning"
                      x-small
                      text
                      dark
                      class="ml-1"
                      :to="{
                        name: 'servicios.rs_refounds.analyze',
                        params: { id: data.id },
                      }"
                    >
                      <v-icon small> mdi-account-cash </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Documentación'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    log.permissions.rss_rs_refounds.update &&
                    log.role_id != 7 &&
                    log.role_id != 8 &&
                    data.insured_id &&
                    (data.rs_refound_bills_xmls > 0 ||
                      !data.refound_with_bills) &&
                    (data.accident == null ||
                      (data.rs_service &&
                        data.rs_service.is_revision_approved == 1)) &&
                    (!data.rs_refound_rejected ||
                      data.rs_refound_rejected.response)
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="
                        data.rs_service &&
                        data.rs_service.is_revision_approved == 1
                          ? 'grey'
                          : 'pink'
                      "
                      x-small
                      text
                      dark
                      class="ml-1"
                      :to="{
                        name: 'servicios.rs_refounds.medical_revision',
                        params: { id: data.id },
                      }"
                    >
                      <v-icon small> small mdi-medical-bag </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Seg. Médico'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    log.permissions.rss_rs_refounds.update && !data.discharged
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="success"
                      x-small
                      text
                      class="ml-1"
                      @click.prevent="dischargedHandle"
                    >
                      <v-icon small> mdi-send-check</v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Terminar'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Folio" :value="data.folio" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="F. Creación" :value="data.created_at" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <span class="text-caption font-weight-bold" v-text="'INFO'" />
                  <br />
                  <v-icon v-if="data.external" small> mdi-close </v-icon>
                  <v-icon v-else color="success" small> mdi-check </v-icon>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Correo electrónico" :value="data.email" />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData
                    label="Correo electrónico alterno"
                    :value="data.email2"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <ViewData label="Teléfono" :value="data.phone" />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Detalle de la solicitud"
                    :value="data.detail"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'ASEGURADO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip v-if="data.insured" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="black"
                      x-small
                      text
                      dark
                      @click.prevent="sinisterRepDlg"
                    >
                      <v-icon small> mdi-file-chart </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Reporte Siniestralidad'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row dense :class="data.insured ? '' : 'warning--text'">
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Nombre" :value="data.insured_full_name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="ID SM" :value="data.enrollment" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Núm. credencial"
                    :value="data.insured ? data.insured.credential : '-'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="F. Nacimiento"
                    :value="data.insured ? data.insured.birthday : '-'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Vigencia"
                    :value="data.insured ? data.insured.validity : '-'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Contratante" :value="data.contractor" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Oficina" :value="data.office_address" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <div
                    class="text-caption font-weight-bold"
                    v-text="'Trat. méd'"
                  />
                  <div v-if="data.insured">
                    <v-icon v-if="data.insured.medical_treatment" small>
                      mdi-close
                    </v-icon>
                    <v-icon v-else color="success" small> mdi-check </v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'DOCUMENTOS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="20px" v-text="'#'" />
                          <th v-text="'Descripción'" />
                          <th width="50px" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(rs_document, i) in data.rs_documents"
                          :key="i"
                        >
                          <td class="font-weight-bold" v-text="i + 1" />
                          <td v-text="rs_document.description" />
                          <td>
                            <v-tooltip v-if="rs_document.b64" left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  class="ml-1"
                                  v-on="on"
                                  text
                                  x-small
                                  color="warning"
                                  @click="
                                    fileDownload(
                                      rs_document.b64,
                                      rs_document.ext,
                                      'doc_' + (i + 1)
                                    )
                                  "
                                >
                                  <v-icon small> mdi-download </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Descargar'" />
                            </v-tooltip>
                            <span
                              v-else
                              class="font-weight-bold warning--text"
                              v-text="'Archivo no encontrado'"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2
                  class="text-caption"
                  v-text="
                    'FACTURAS' +
                    (data.rs_refound_bills.length > 0 ? '' : ' (NO REG.)')
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-divider />
            <v-card-text v-if="data.rs_refound_bills.length > 0">
              <v-row
                v-for="(rs_refound_bill, i) in data.rs_refound_bills"
                :key="i"
                dense
              >
                <v-col
                  cols="12"
                  :class="
                    rs_refound_bill.active
                      ? rs_refound_bill.rs_refound_bill_items.length > 0
                        ? 'grey darken-2 white--text'
                        : ''
                      : 'red darken-2 white--text'
                  "
                >
                  <v-row dense>
                    <v-col cols="9">
                      <h4
                        v-text="
                          i +
                          1 +
                          ') ' +
                          (rs_refound_bill.xml_folio
                            ? rs_refound_bill.xml_folio
                            : ' XML SIN ANALIZAR')
                        "
                        class="py-1 px-1"
                      />
                    </v-col>
                    <v-col cols="3" class="text-right mt-1">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            text
                            x-small
                            :color="
                              rs_refound_bill.xml_folio ? 'white' : 'orange'
                            "
                            class="mr-1"
                            @click="
                              fileDownload(
                                rs_refound_bill.pdf_b64,
                                'pdf',
                                'fac_' + (i + 1)
                              )
                            "
                          >
                            <v-icon small> mdi-file-document </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver PDF'" />
                      </v-tooltip>
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            text
                            x-small
                            :color="
                              rs_refound_bill.xml_folio ? 'white' : 'orange'
                            "
                            class="mr-1"
                            @click="
                              fileDownload(
                                rs_refound_bill.xml_b64,
                                'xml',
                                'fac_' + (i + 1)
                              )
                            "
                          >
                            <v-icon small> mdi-xml </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Ver XML'" />
                      </v-tooltip>
                      <v-tooltip
                        v-if="log.role_id == 1 && rs_refound_bill.active"
                        left
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            text
                            x-small
                            color="error "
                            class="mr-1"
                            @click="rsRefoundBillDelete(rs_refound_bill.id)"
                          >
                            <v-icon small> mdi-close </v-icon>
                          </v-btn>
                        </template>
                        <span v-text="'Eliminar'" />
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="rs_refound_bill.emisor_code_tax"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    label="Emisor"
                    :value="
                      rs_refound_bill.emisor_code_tax +
                      ' | ' +
                      rs_refound_bill.emisor_legal_name
                    "
                  />
                </v-col>
                <v-col
                  v-if="rs_refound_bill.receptor_code_tax"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ViewData
                    :class="rs_refound_bill.code_tax_valid ? '' : 'red--text'"
                    label="Receptor"
                    :value="
                      rs_refound_bill.receptor_code_tax +
                      ' | ' +
                      rs_refound_bill.receptor_legal_name
                    "
                  />
                </v-col>
                <v-col v-if="rs_refound_bill.xml_date" cols="12" xs="12" md="4">
                  <ViewData label="Fecha" :value="rs_refound_bill.xml_date" />
                </v-col>
                <v-col
                  v-if="rs_refound_bill.rs_refound_bill_items.length > 0"
                  cols="12"
                >
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th width="40px">#</th>
                          <th>Descripción</th>
                          <th width="150px">Cantidad</th>
                          <th width="150px">Valor unitario</th>
                          <th width="150px">Importe</th>
                          <th width="150px">IVA</th>
                          <th width="150px">Monto reclamado</th>
                          <th width="150px">Gastos cubiertos</th>
                          <th width="150px">Gastos no cubiertos</th>
                          <th width="200px">Observación</th>
                          <th width="40px"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            rs_refound_bill_item, j
                          ) in rs_refound_bill.rs_refound_bill_items"
                          :key="j"
                        >
                          <td v-text="j + 1" />
                          <td v-text="rs_refound_bill_item.item" />
                          <td v-text="rs_refound_bill_item.quantity" />
                          <td
                            v-text="numberFormat(rs_refound_bill_item.amount)"
                          />
                          <td
                            v-text="
                              numberFormat(rs_refound_bill_item.total_amount)
                            "
                          />
                          <td
                            v-text="
                              rs_refound_bill_item.tax +
                              '% | ' +
                              numberFormat(rs_refound_bill_item.tax_amount)
                            "
                          />
                          <td>
                            <b
                              v-text="
                                numberFormat(
                                  rs_refound_bill_item.total_tax_amount
                                )
                              "
                            />
                          </td>
                          <td>
                            <span
                              v-text="
                                numberFormat(
                                  rs_refound_bill_item.refound_amount
                                )
                              "
                            />
                          </td>
                          <td>
                            <b
                              v-text="
                                numberFormat(rs_refound_bill_item.no_refound)
                              "
                            />
                          </td>
                          <td
                            v-text="
                              rs_refound_bill_item.rs_refound_item_type
                                ? rs_refound_bill_item.rs_refound_item_type
                                    .rs_refound_item_type
                                : 'N/A'
                            "
                          />
                          <td>
                            <v-icon
                              v-if="rs_refound_bill_item.revision"
                              color="success"
                              small
                            >
                              mdi-checkbox-marked
                            </v-icon>
                            <v-icon v-else small>
                              mdi-checkbox-blank-outline
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2
                  class="text-caption"
                  v-text="
                    'SEG. MÉDICO' + (data.accident != null ? '' : ' (NO REG.)')
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip
                  v-if="
                    data.accident != null &&
                    data.rs_service &&
                    data.rs_service.is_revision_approved == 1
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="warning"
                      x-small
                      text
                      dark
                      class="ml-1"
                      @click="rsServiceRevisionDlg"
                    >
                      <v-icon small left> mdi-timeline-clock</v-icon>
                      {{ data.rs_service.rs_service_revisions.length }}
                    </v-btn>
                  </template>
                  <span v-text="'Revisar'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    data.accident != null &&
                    data.rs_service &&
                    data.rs_service.is_revision_approved == 2
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="error"
                      x-small
                      text
                      dark
                      class="ml-1"
                      @click="rsServiceRevisionDlg"
                    >
                      <v-icon small left> mdi-timeline-remove </v-icon>
                      {{ data.rs_service.rs_service_revisions.length }}
                    </v-btn>
                  </template>
                  <span v-text="'Ver revisiones'" />
                </v-tooltip>
                <v-tooltip
                  v-if="
                    data.accident != null &&
                    data.rs_service &&
                    data.rs_service.is_revision_approved == 3
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="success"
                      x-small
                      text
                      dark
                      class="ml-1"
                      @click="rsServiceRevisionDlg"
                    >
                      <v-icon small left> mdi-timeline-check </v-icon>
                      {{ data.rs_service.rs_service_revisions.length }}
                    </v-btn>
                  </template>
                  <span v-text="'Ver revisiones'" />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-card-text v-if="data.accident != null">
              <v-row dense>
                <v-col cols="12">
                  <span
                    class="text-caption font-weight-bold"
                    v-text="'Diagnostico definitivo (ICD)'"
                  />
                  <br />
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-text="'#'" />
                          <th v-text="'Código'" />
                          <th v-text="'Descripción'" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(rs_icd, i) in data.rs_icds" :key="i">
                          <td v-text="i + 1" />
                          <td v-text="rs_icd.icd.code" />
                          <td v-text="rs_icd.icd.icd" />
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <ViewData
                    label="Padecimiento"
                    :value="data.accident ? 'ACCIDENTE' : 'ENFERMEDAD'"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <ViewData
                    label="Siniestro"
                    :value="data.initial ? 'INICIAL' : 'COMPLEMENTO'"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <ViewData
                    label="Prexistente / Riesgo"
                    :value="data.preexisting ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="CPT"
                    :value="data.cpt.code + ' | ' + data.cpt.cpt"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData label="Comentario" :value="data.comment" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2
                  class="text-caption"
                  v-text="
                    'CARTA' + (data.rs_service != null ? '' : ' (NO REG.)')
                  "
                />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-tooltip
                  v-if="data.rs_service && data.rs_service.letter_generated"
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="pink darken-2"
                      x-small
                      text
                      dark
                      @click.prevent="letterView(item)"
                    >
                      <v-icon small> mdi-file-certificate </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Ver carta'" />
                </v-tooltip>

                <v-tooltip
                  v-if="
                    log.role_id == 1 &&
                    !data.discharged &&
                    data.rs_service_bill &&
                    !data.rs_service_bill.paid_amount
                  "
                  left
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      :color="
                        data.rs_service_bill.block_payment ? 'error' : 'gray'
                      "
                      x-small
                      icon
                      class="ml-1"
                      @click.prevent="blockPaymentHandle"
                    >
                      <v-icon>
                        {{
                          data.rs_service_bill.block_payment
                            ? "mdi-currency-usd-off"
                            : "mdi-currency-usd"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span
                    v-text="
                      data.rs_service_bill.block_payment
                        ? 'Pago bloqueado'
                        : 'Puede pagarse'
                    "
                  />
                </v-tooltip>
              </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-card-text v-if="data.rs_service != null">
              <v-row dense>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Tope máximo"
                    :value="numberFormat(data.rs_service.assured_amount)"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Fecha"
                    :value="
                      data.rs_service.letter_generated
                        ? data.rs_service.letter_generated
                        : 'PENDIENTE'
                    "
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="Generada por"
                    :value="
                      data.rs_service.letter_generated
                        ? data.rs_service.letter_generated_by.email
                        : 'PENDIENTE'
                    "
                  />
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <ViewData
                    label="F. promesa pago"
                    :value="
                      data.rs_service && data.rs_service.rs_service_bill
                        ? data.rs_service.rs_service_bill.pay_date
                        : 'PENDIENTE'
                    "
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Observaciones"
                    :value="data.rs_service.caption"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <h2 class="text-caption" v-text="'REGISTRO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items />
            </v-toolbar>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="data && data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="data && data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-tooltip
            v-if="log.permissions.rss_rs_refounds.delete && !data.discharged"
            top
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="error"
                @click.stop="destroyHandle"
                :loading="ldg"
              >
                <v-icon> mdi-sticker-remove-outline </v-icon>
              </v-btn>
            </template>
            <span v-text="'Cancelar solicitud'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="rs_comment_detail_dlg"
      scrollable
      persistent
      max-width="600"
    >
      <v-card
        tile
        :disabled="rs_comment_detail_dlg_ldg"
        :loading="rs_comment_detail_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> COMENTARIOS | OBSERVACIONES </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rs_comment_detail_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-form
                v-on:submit.prevent
                ref="rs_comment_detail_form"
                lazy-validation
              >
                <v-row dense>
                  <v-col cols="12" class="mt-3" />
                  <v-col cols="12">
                    <v-card tile>
                      <v-toolbar elevation="1" dense>
                        <v-toolbar-title>
                          <span class="text-caption" v-text="'DETALLE'" />
                        </v-toolbar-title>
                        <v-spacer />
                        <v-toolbar-items />
                      </v-toolbar>
                      <v-spacer />
                      <v-card-text>
                        <v-row dense>
                          <v-col
                            cols="12"
                            v-for="(rs_comment_detail, i) in rs_comment_details"
                            :key="i"
                          >
                            <v-row dense>
                              <v-col cols="11">
                                <span
                                  class="text-caption font-weight-bold"
                                  v-text="rs_comment_detail.created_at"
                                />
                                <v-icon
                                  small
                                  :color="
                                    rs_comment_detail.response
                                      ? 'info'
                                      : 'warning'
                                  "
                                  right
                                >
                                  mdi-email-arrow-{{
                                    rs_comment_detail.response
                                      ? "left"
                                      : "right"
                                  }}
                                </v-icon>
                                <br />
                                <span
                                  class="text-description"
                                  v-text="rs_comment_detail.detail"
                                />
                              </v-col>
                              <v-col cols="1">
                                <v-btn
                                  v-if="rs_comment_detail.document_url"
                                  fab
                                  x-small
                                  class="ml-1"
                                  color="warning"
                                  :href="
                                    url_documents +
                                    '/rs_comment_details/' +
                                    rs_comment_detail.document_url
                                  "
                                  target="_blank"
                                >
                                  <v-icon> mdi-download </v-icon>
                                </v-btn>
                              </v-col>
                              <v-col cols="12">
                                <v-divider class="pb-1" />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row
                          v-if="rs_comment_detail_response && !data.discharged"
                          cols="12"
                          dense
                        >
                          <v-col cols="12">
                            <v-textarea
                              label="Comentario"
                              rows="3"
                              v-model="rs_comment_detail.detail"
                              dense
                              :rules="rules.required"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-file-input
                              label="Archivo digital (PDF)"
                              v-model="rs_comment_detail.document_file_0"
                              accept="image/,.pdf"
                              :rules="rules.fileLmt"
                              show-size
                              prepend-icon=""
                              append-icon="$file"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
              <v-row
                v-if="rs_comment_detail_response && !data.discharged"
                dense
                class="mt-4"
              >
                <v-col cols="12">
                  <v-btn
                    block
                    color="info"
                    @click.prevent="rsCommentDetailHandle"
                  >
                    Enviar
                    <v-icon right> mdi-send </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sinister_rep_dlg" scrollable persistent max-width="1800">
      <v-card
        tile
        :disabled="sinister_rep_dlg_ldg"
        :loading="sinister_rep_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> REPORTE </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="sinister_rep_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="sinister_rep">
          <v-row dense>
            <v-col cols="12" class="pt-3">
              <h4 v-text="'SINIESTRALIDAD'" />
            </v-col>
            <v-col v-if="sinister_rep.list.length > 0" cols="12">
              <v-row dense v-for="(icd, i) in sinister_rep.list" :key="i">
                <v-col cols="12">
                  <span
                    class="text-caption"
                    v-text="icd.code + ' | ' + icd.icd"
                  />
                </v-col>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th v-text="'#'" />
                          <th v-text="'Tipo'" />
                          <th v-text="'Proveedor'" />
                          <th v-text="'T. Prov. | Espec.'" />
                          <th v-text="'Folio'" />
                          <th v-text="'Fecha'" />
                          <th v-text="'Padecimiento'" />
                          <th v-text="'Siniestro'" />
                          <th v-text="'Núm. Siniestro'" />
                          <th v-text="'Deducible'" />
                          <th v-text="'Coaseguro %'" />
                          <th v-text="'Facturado'" />
                          <th v-text="'Monto'" />
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, j) in icd.items" :key="j">
                          <td v-text="`${j + 1}`" />
                          <td v-text="item.type" />
                          <td v-text="item.provider" />
                          <td v-text="item.provider_type" />
                          <td v-text="item.folio" />
                          <td v-text="item.date" />
                          <td
                            v-text="item.accident ? 'ACCIDENTE' : 'ENFERMEDAD'"
                          />
                          <td
                            v-text="item.initial ? 'INICIAL' : 'COMPLEMENTARIO'"
                          />
                          <td v-text="item.sinister_number" />
                          <td v-text="numberFormat(item.deducible_amount)" />
                          <td v-text="item.coinsurance" />
                          <td>
                            <v-icon small :color="item.bill ? 'success' : ''">
                              mdi-{{ item.bill ? "check" : "close" }}
                            </v-icon>
                          </td>
                          <td v-text="numberFormat(item.amount)" />
                          <td>
                            <v-tooltip v-if="item.comment" left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  fab
                                  dark
                                  x-small
                                  class="ml-1"
                                  color="info"
                                  @click="commentDlg(item)"
                                >
                                  <v-icon> mdi-message-draw </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Seg. médico comentario'" />
                            </v-tooltip>
                          </td>
                        </tr>
                        <tr>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            <b v-text="numberFormat(icd.amount)" />
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else cols="12"> SIN REGISTROS </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="comment_dlg" scrollable persistent max-width="600px">
      <v-card v-if="comment" tile>
        <v-toolbar dark dense>
          <v-toolbar-title> FOLIO {{ comment.folio }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="comment_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'SEG. MÉDICO COMENTARIO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p v-text="comment.comment" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rs_service_revision_dlg"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card
        tile
        :disabled="rs_service_revision_dlg_ldg"
        :loading="rs_service_revision_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-if="rs_service_revision">
            Revisión ({{
              rs_service_revision.rs_service_revisions.length + 1
            }})</v-toolbar-title
          >
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rs_service_revision_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rs_service_revision">
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col
              v-if="
                rs_service_revision.is_revision_approved == 1 &&
                (rs_service_revision.rs_service_revisions.length == 0 ||
                  rs_service_revision.rs_service_revisions[
                    rs_service_revision.rs_service_revisions.length - 1
                  ].created_by_id != log.id)
              "
              cols="6"
            >
              <v-btn
                block
                small
                color="error"
                @click.prevent="rsServiceRevisionHandle(false)"
              >
                Rechazar
                <v-icon right> mdi-cancel </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="
                rs_service_revision.is_revision_approved == 1 &&
                (rs_service_revision.rs_service_revisions.length == 0 ||
                  rs_service_revision.rs_service_revisions[
                    rs_service_revision.rs_service_revisions.length - 1
                  ].created_by_id != log.id)
              "
              cols="6"
            >
              <v-btn
                block
                small
                color="success"
                @click.prevent="rsServiceRevisionHandle(true)"
              >
                Aceptar
                <v-icon right> mdi-check </v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="
                rs_service_revision.is_revision_approved == 1 &&
                (rs_service_revision.rs_service_revisions.length == 0 ||
                  rs_service_revision.rs_service_revisions[
                    rs_service_revision.rs_service_revisions.length - 1
                  ].created_by_id != log.id)
              "
              cols="12"
            >
              <v-spacer />
            </v-col>
            <v-col
              v-if="rs_service_revision.rs_service_revisions.length > 0"
              cols="12"
            >
              Historial
              <br />
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="20px" v-text="'#'" />
                      <th v-text="'Fecha'" />
                      <th v-text="'Usuario'" />
                      <th width="50px" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        item, i
                      ) in rs_service_revision.rs_service_revisions"
                      :key="i"
                    >
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td v-text="item.created_at" />
                      <td v-text="item.created_by.email" />
                      <td>
                        <v-icon v-if="item.valid" small color="success">
                          mdi-check
                        </v-icon>
                        <v-icon v-else small color="error"> mdi-close </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="insureds_dlg" scrollable persistent max-width="1800">
      <v-card tile :loading="insureds_dlg_ldg" :disabled="insureds_dlg_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> ASEGURADOS </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insureds_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insured">
          <v-row dense class="pt-5">
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="Nombre"
                v-model="insured.name"
                type="text"
                dense
                :disabled="insureds.length > 0"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="A. Paterno"
                v-model="insured.first_surname"
                type="text"
                dense
                :disabled="insureds.length > 0"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="A. Materno"
                v-model="insured.second_surname"
                type="text"
                dense
                :disabled="insureds.length > 0"
              />
            </v-col>
            <v-col cols="12" xs="12" md="3">
              <v-text-field
                label="ID SM"
                v-model="insured.enrollment"
                type="text"
                dense
                :disabled="insureds.length > 0"
              />
            </v-col>
            <v-col cols="12">
              <v-btn
                v-if="insureds.length == 0"
                block
                color="info"
                small
                :disabled="
                  insured.name == '' &&
                  insured.first_surname == '' &&
                  insured.second_surname == '' &&
                  insured.enrollment == ''
                "
                @click.prevent="insuredsSearch"
              >
                Buscar
                <v-icon right> mdi-magnify </v-icon>
              </v-btn>
              <v-btn v-else block small @click.prevent="insureds = []">
                Cambiar parametros
                <v-icon right> mdi-refresh </v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="insureds.length > 0" cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'Nombre'" />
                      <th v-text="'ID SM'" />
                      <th v-text="'Núm. credencial'" />
                      <th v-text="'F. Nacimiento'" />
                      <th v-text="'Vigencia'" />
                      <th v-text="'Contratante'" />
                      <th v-text="'Oficina'" />
                      <th v-text="'Trat. méd'" />
                      <th v-text="''" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in insureds" :key="i">
                      <td v-text="item.full_name" />
                      <td v-text="item.enrollment" />
                      <td v-text="item.credential" />
                      <td v-text="item.birthday" />
                      <td
                        v-text="item.validity"
                        :class="item.expired ? 'error--text' : ''"
                      />
                      <td v-text="item.contractor.name" />
                      <td v-text="item.office_address" />
                      <td>
                        <v-icon v-if="item.medical_treatment" small>
                          mdi-close
                        </v-icon>
                        <v-icon v-else color="success" small>
                          mdi-check
                        </v-icon>
                      </td>
                      <td>
                        <v-tooltip v-if="!item.expired" left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="warning"
                              @click="insuredSelect(item)"
                            >
                              <v-icon> mdi-send </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Seleccionar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgAlert,
  msgConfirm,
  base64ToArrayBuffer,
  dateTimeToFile,
  numberFormat,
  dateTimeNow,
  URL_PDF,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    ViewData,
  },
  data() {
    return {
      url_pdf: URL_PDF, // QUITAR POR BASE 64
      rules: rules(),
      numberFormat: numberFormat,
      rs_id: parseInt(this.$route.params.rs_id),
      log: this.$store.getters.getLogin,
      data: null,
      ldg: true,
      sinister_rep: null,
      sinister_rep_dlg: false,
      sinister_rep_dlg_ldg: false,
      comment: null,
      comment_dlg: false,
      rs_service_revision: null,
      rs_service_revision_dlg_ldg: false,
      rs_service_revision_dlg: false,
      rs_comment_detail: null,
      rs_comment_details: [],
      rs_comment_detail_response: false,
      rs_comment_detail_dlg: false,
      rs_comment_detail_dlg_ldg: false,
      insureds_dlg: false,
      insureds_dlg_ldg: false,
      insureds: [],
      insured: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.ldg = true;
      this.data = null;

      Axios.get(
        URL_API + "/rss/" + this.rs_id + "/refounds",
        headersToken(this.log.token)
      ).then((res) => {
        if (res.data.success) {
          this.data = res.data.data;
          this.ldg = false;
        } else {
          this.$swal.fire(msgAlert("error", "Contacte al equipo de soporte"));
        }
      });
    },
    fileDownload(b64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(b64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    destroyHandle() {
      this.$swal
        .fire(msgConfirm("¿Confirma cancelar solicitud?"))
        .then((res) => {
          if (res.isConfirmed) {
            this.ldg = true;

            Axios.delete(
              URL_API + "/rss/" + this.rs_id,
              headersToken(this.log.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.$router.push({ name: "servicios.rs_refounds" });
              }
            });
          }
        });
    },
    rsCommentDetailDlg() {
      this.rs_comment_detail = {
        detail: "",
        response: true,
        document_file_0: null,
        rs_id: this.data.id,
      };
      this.rs_comment_details = [];

      this.rs_comment_detail_response = false;
      this.rs_comment_detail_dlg = true;
      this.rs_comment_detail_dlg_ldg = true;

      Axios.get(
        URL_API + "/rss/" + this.rs_id + "/comments",
        headersToken(this.log.token)
      ).then((resp) => {
        this.rs_comment_details = resp.data.data;

        if (
          this.rs_comment_details.length == 0 ||
          !this.rs_comment_details[this.rs_comment_details.length - 1].response
        ) {
          this.rs_comment_detail_response = true;
        }

        this.rs_comment_detail_dlg_ldg = false;
      });
    },
    blockPaymentHandle() {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma " +
              (this.data.rs_service_bill.block_payment ? "des" : "") +
              "bloquear el pago del reembolso?"
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.ldg = true;

            Axios.post(
              URL_API + "/rss/services/bills/block/payment",
              {
                rs_service_bill_id: this.data.rs_service_bill.id,
              },
              headersToken(this.log.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getData();
              }
            });
          }
        });
    },
    rsCommentDetailHandle() {
      if (this.$refs.rs_comment_detail_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma enviar comentario?`))
          .then((resp) => {
            if (resp.isConfirmed) {
              this.rs_comment_detail_dlg_ldg = true;

              Axios.post(
                URL_API + "/rss/comments/detail",
                toFormData(this.rs_comment_detail),
                headersToken(this.log.token)
              ).then((resp) => {
                this.$swal.fire(
                  msgAlert(
                    resp.data.success ? "success" : "error",
                    resp.data.message
                  )
                );

                if (resp.data.success) {
                  this.getData();
                  this.rs_comment_detail_dlg = false;
                } else {
                  console.log(resp.data.message);
                }

                this.rs_comment_detail_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    dischargedHandle() {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma el terminar el reembolso " + this.data.folio + "?"
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.ldg = true;

            Axios.post(
              URL_API + "/rss/discharge",
              {
                rs_id: this.rs_id,
                discharged: dateTimeNow(),
              },
              headersToken(this.log.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getData();
              }
            });
          }
        });
    },
    sinisterRepDlg() {
      this.sinister_rep = null;
      this.sinister_rep_dlg_ldg = true;
      this.sinister_rep_dlg = true;

      Axios.get(
        URL_API +
          "/insureds/sinister/report?insured_id=" +
          this.data.insured_id +
          "&icd_id=0",
        headersToken(this.log.token)
      ).then((res) => {
        this.sinister_rep = res.data.data;
        this.sinister_rep_dlg_ldg = false;
      });
    },
    commentDlg(item) {
      this.comment = item;
      this.comment_dlg = true;
    },
    rsRefoundBillDelete(id) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar esta factura?`))
        .then((res) => {
          if (res.isConfirmed) {
            this.ldg = true;

            Axios.delete(
              URL_API + "/rs/refound/bills/" + id,
              headersToken(this.log.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getData();
              }

              this.ldg = false;
            });
          }
        });
    },
    rsServiceRevisionDlg() {
      this.rs_service_revision = {
        rs_service_id: this.data.rs_service.id,
        valid: null,
        rs_service_revisions: this.data.rs_service.rs_service_revisions,
        is_revision_approved: this.data.rs_service.is_revision_approved,
      };
      this.rs_service_revision_dlg_ldg = false;
      this.rs_service_revision_dlg = true;
    },
    rsServiceRevisionHandle(valid) {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma " + (valid ? "ACEPTAR" : "RECHAZAR") + " la revisión?"
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.rs_service_revision.valid = valid;
            this.rs_service_revision_dlg = true;

            Axios.post(
              URL_API + "/rs/refound/revision",
              this.rs_service_revision,
              headersToken(this.log.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.getData();
              }

              this.rs_service_revision_dlg = false;
            });
          }
        });
    },
    insuredsDlg() {
      this.insureds = [];
      this.insured = {
        name: this.data.name,
        first_surname: this.data.first_surname,
        second_surname: this.data.second_surname,
        enrollment: this.data.enrollment,
        insurance_id: this.data.insurance_id,
      };
      this.insureds_dlg_ldg = false;
      this.insureds_dlg = true;
    },
    insuredsSearch() {
      this.insureds_dlg_ldg = true;

      Axios.post(
        URL_API + "/rss/refound/insureds/search",
        this.insured,
        headersToken(this.log.token)
      ).then((res) => {
        if (res.data.data.length > 0) {
          this.insureds = res.data.data;
        } else {
          this.$swal.fire(
            msgAlert("warning", "Sin resultados para la busqueda")
          );
        }

        this.insureds_dlg_ldg = false;
      });
    },
    insuredSelect(item) {
      this.$swal
        .fire(msgConfirm("¿Confirma editar datos del asegurado?"))
        .then((res) => {
          if (res.isConfirmed) {
            this.insureds_dlg_ldg = true;

            Axios.post(
              URL_API + "/rss/refound/insured/update",
              {
                rs_id: this.rs_id,
                insured: item,
              },
              headersToken(this.log.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.insureds_dlg = false;
                this.getData();
              }

              this.insureds_dlg_ldg = false;
            });
          }
        });
    },
    letterView() {
      // CAMBIAR A BASE 64
      window
        .open(this.url_pdf + "/rs/refound/bills/" + this.rs_id, "_blank")
        .focus();
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.log.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getData();
  },
};
</script>